@use 'sass:math';
@import '../../../styles/variables';

.ptr-Table {
  font-size: $b1;
  background: var(--accent0);
  width: 100%;
  border-radius: .25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
}

.ptr-TableRow:not(:last-child) {
  border-bottom: .05rem solid var(--base15);
}

.ptr-TableHeaderCell {
  font-weight: bold;
  text-align: left;
  background: var(--accent15);
  color: var(--accent80);
}

.ptr-TableCell {
  color: var(--base80);
}

.ptr-TableHeaderCell,
.ptr-TableCell {
  padding: .35rem .5rem;
}

.ptr-TableHeaderCell:first-child,
.ptr-TableCell:first-child {
  padding-left: .85rem;
}

.ptr-TableHeaderCell:last-child,
.ptr-TableCell:last-child {
  padding-right: .85rem;
}

.ptr-TableHeaderCell.is-right,
.ptr-TableCell.is-right {
  text-align: right;
}

.ptr-TableHeader .ptr-TableHeaderCell:first-child {
  border-top-left-radius: .25rem;
}

.ptr-TableHeader .ptr-TableHeaderCell:last-child {
  border-top-right-radius: .25rem;
}

.ptr-TableCellIcon {
  display: inline-block;
  position: relative;
  top: .15rem;
  width: .85rem;
  height: .85rem;
  border-radius: .5rem;
  margin-right: .5rem;
}


@use 'sass:math';
@import '../../../styles/variables';

.eo4ams-App {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.eo4ams-AppContent {
  flex: 1 1;
  position: relative;
  z-index: 0;
  height: 100%;
  display: flex;
}
@use 'sass:math';
@import '../../../../../../styles/variables';


.eo4ams-CaseCard {
  position: relative;
  background: rgba(var(--accent50rgb), .7);
  width: 20rem;
  height: 8rem;
  border-radius: .05rem;
  padding: m(1) m(1) m(.75);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @include maxWidth('small') {
    width: 18rem;
    height: 8rem;
  }
}

.eo4ams-CaseCard:hover {
  background: rgba(var(--accent50rgb), .9);
}

.eo4ams-CaseCard-wrapper.disabled .eo4ams-CaseCard {
  background: rgba(100, 100, 100, .7);
  cursor: auto;
}

.eo4ams-CaseCard-title {
  margin: 0;
  flex: 1;
  font-size: $b2;
  font-weight: 400;
}

.eo4ams-CaseCard-wrapper.disabled .eo4ams-CaseCard-title {
  color: var(--base50);
}

.eo4ams-CaseCard .ptr-icon {
  width: 2rem;
  height: 2rem;
  transform: rotate(180deg);

  @include maxWidth('small') {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.eo4ams-CaseCard .ptr-icon .line {
  fill: var(--accent80)
}
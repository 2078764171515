@use 'sass:math';
@import '../../styles/variables';

.eo4ams-Logo {
  font-family: "Viga", sans-serif;
  font-weight: normal;
  color: var(--accent50);
}

.eo4ams-Logo.is-light {
  color: var(--accent65);
}

.eo4ams-Logo em {
  color: var(--accent100);
  margin: 0;
  padding: 0;
}
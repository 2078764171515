@use 'sass:math';
@import '../../../../../../../../../styles/variables';

.eo4ams-ClassificationSemaphore {

}

.eo4ams-ClassificationSemaphore-header {
  padding: 0 $sidePanelPadding 1rem $sidePanelPadding;
  background: var(--accent15);
}

.eo4ams-ClassificationSemaphore .eo4ams-CaseStyle-legend {
  margin-bottom: 0;
  min-height: 8.7rem;
}
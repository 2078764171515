@use 'sass:math';
@import '../../../../styles/variables';

$mapBorder: 0.05rem solid var(--base50); // the width in rem fixes the ugly white space between border and content in Chrome


// Maps
// Map set
.mzecrEo4ams21 .ptr-map-set {
  position: relative;
  z-index: 2;
}

.mzecrEo4ams21 .ptr-map-grid .ptr-map-grid-cell .ptr-map {
  border: 0;
}

.mzecrEo4ams21 .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.mzecrEo4ams21 .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}

// MapInfoElements
.eo4ams-MapInfoElements {
  pointer-events: none;
  position: absolute;
  bottom: .35rem;
  left: .5rem;
  display: flex;
  grid-gap: .35rem;
  flex-direction: column;
  z-index: 9;
}

// AttributionScaleContainer
.eo4ams-AttributionScaleContainer {
  display: flex;
  grid-gap: .35rem;
}

// Map set controls
.eo4ams-MapSetControls {
  position: absolute;
  right: .75rem;
  bottom: .75rem;
  grid-gap: .5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;
}
@use 'sass:math';
@import '../../../../../../styles/variables';

$attributesTableGridGap: 1.3rem;

.eo4ams-MowingGrazingAttributes-switch {
  margin: m(math.div(1,3)) m(1);
}

//.eo4ams-MowingGrazingAttributes-switch  .ptr-button.with-data:before {
//  content: "";
//  position: absolute;
//  top: -0.05rem;
//  right: 0;
//  border-top: 4px solid var(--accent50);
//  border-right: 4px solid var(--accent50);
//  border-left: 4px solid transparent;
//  border-bottom: 4px solid transparent;
//}

.eo4ams-MowingGrazingAttributes-switch  .ptr-button:not(.with-data) {
    color: rgba(var(--base100rgb), 0.4);
  }

.eo4ams-MowingGrazingAttributes-section:not(:last-child) {
  margin: m(math.div(2,3)) 0;
}

.eo4ams-MowingGrazingAttributes-section > h6 {
  padding: .15rem m(1);
  font-size: $b1;
  font-weight: bold;
  color: var(--accent55);
}

.eo4ams-MowingGrazingAttributes-section > p {
  padding: .15rem m(1);
  margin: 0;
  font-size: $b1;
  font-style: italic;
  color: $secondaryTextColor;
}

// table
.eo4ams-AttributesTable {
  width: 100%;
  font-size: $b1;
}

.eo4ams-AttributesTable-header {
  display: flex;
  font-weight: bold;
  color: $baseTextColor;
  line-height: 1.15;
  margin: 0 m(1);
  grid-gap: $attributesTableGridGap;
  padding: .25rem 0;
  border-bottom: 1px dashed var(--base40);
  align-items: flex-end;
}

.eo4ams-AttributesTable-record {
  display: flex;
  line-height: 1.15;
  padding: 0.25rem m(1);
  color: $baseTextColor;
  align-items: baseline;
  grid-gap: $attributesTableGridGap;
}

.eo4ams-AttributesTable-record:hover {
  background: var(--accent15);
}

.eo4ams-AttributesTable-header > div,
.eo4ams-AttributesTable-record > div {
  width: calc(20% - 0.6rem);
}
@use 'sass:math';
@import '../../../../../styles/variables';

.eo4ams-CaseSelect {
  width: 100%;
}

// current
.ptr-panther-select-current.eo4ams-CaseSelect-current {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--accent25);
  display: flex;
  box-shadow: $floatingElementShadow;
  width: $sidePanelWidth;

}

.eo4ams-CaseSelect-current-placeholder {
  padding-left: .7rem;
  color: var(--accent20);
  font-style: italic;
}

.eo4ams-CaseSelect-current-value {
  display: flex;
  align-items: center;
  padding: 0.25rem .5rem 0.25rem 1rem;

  @include maxWidth('medium') {
    padding: .25rem .25rem 0.25rem 1rem;
    height: 3rem;
  }
}

.eo4ams-CaseSelect-current-value > .eo4ams-Logo {
  font-size: $a2;

  @include maxWidth('medium') {
    display: none;
  }
}

.eo4ams-CaseSelect-current-value > h2 {
  color: var(--accent95);
  margin: 0 0 0 1rem;
  padding-left: 1rem;
  font-size: $b2;
  flex: 1;
  white-space: nowrap;
  border-left: 1px solid rgba(255,255,255,.25);

  @include maxWidth('medium') {
    color: var(--accent95);
    margin: 0;
    padding-left: 0;
    font-size: $a1;
    flex: 1;
    white-space: nowrap;
    border-left: none;
  }
}

.eo4ams-CaseSelect-current .ptr-panther-select-current-icon {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
}

.eo4ams-CaseSelect-current .ptr-panther-select-current-icon svg {
  width: 1.25rem;
  height: 1.25rem;
}

.eo4ams-CaseSelect-current .ptr-panther-select-current-icon svg .line {
  fill: $accentedTextColor;
}

// content
.ptr-panther-select-list.eo4ams-CaseSelect-list {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 0;
}

.eo4ams-CaseSelect-list.ptr-panther-select-list > div > div {
  height: 100%;
  transition: transform .3s ease-in-out;
}

.eo4ams-CaseSelect-content:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('../../../../../assets/img/back.jpg');
  background-size: cover;
  background-position: bottom center;
}

.eo4ams-CaseSelect-content:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .7), rgba(0, 0, 0, .7), rgba(0, 0, 0, 1));
}

.eo4ams-CaseSelect-content {
  height: 100%;
  background: rgba(0, 0, 0, 1);
  transition: background-position .3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.eo4ams-CaseSelect.open .eo4ams-CaseSelect-content {
  background-position: 0 100%;
}

// Title
.eo4ams-Title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: m(2) m(1) m(math.div(4,3));
  justify-content: center;
  align-items: center;

  @include maxWidth('small') {
    padding: m(1) m(math.div(2, 3));
  }
}

.eo4ams-Title > .eo4ams-Logo {
  font-size: $a4;

  @include maxWidth('small') {
    font-size: $b4;
  }
}

.eo4ams-Title h1 {
  margin: 0;
  font-weight: normal;
  font-size: $a2;
  letter-spacing: 1px;
  color: var(--base90);
  line-height: 1.25;
  text-align: center;

  @include maxWidth('small') {
    font-size: $b2;
  }
}

// About
.eo4ams-About-ProjectIntroduction {
  padding: m(2) m(1);
  flex: 1;
}

.eo4ams-About-ProjectIntroduction > div, .eo4ams-About-Project > div, .eo4ams-About-Markers > div {
  margin: 0 auto;
  max-width: 50rem;
}

.eo4ams-About-ProjectIntroduction p, .eo4ams-About-Project p, .eo4ams-About-Markers p {
  color: $baseTextColor;
}

.eo4ams-About-Project, .eo4ams-About-Markers {
	padding: m(1);
  flex: 1;
}

.eo4ams-About-link {
  color: $baseTextColor;
}

.eo4ams-About-linkInfo {
  text-decoration: underline;
	cursor: pointer;
}

.eo4ams-Footer {
  padding: m(1) m(1) m(4);
}

.eo4ams-Footer > div {
  margin: 0 auto;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.eo4ams-Footer-logos {
  margin: m(math.div(1, 3)) 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: m(1);
}

.eo4ams-Footer p {
  color: var(--base45);
  font-size: $b1;
  text-align: center;
}

.eo4ams-Footer-logo-image {
  width: 200px;
  opacity: .45;
}

.eo4ams-Footer-logo-image.ekotoxa {
  width: 80px;
}

.eo4ams-Footer-logo path {
  fill: var(--base35);
}

.eo4ams-Footer-logos a {
  margin: 0 m(1);
}

.eo4ams-Footer-logos a:hover .eo4ams-Footer-logo path {
  fill: var(--base55);
}

.eo4ams-Footer-logos a:hover .eo4ams-Footer-logo-image {
  opacity: .65;
}

.eo4ams-Footer-attribution {
  margin-top: m(2);
}

.eo4ams-About-moreInfo {
	color: var(--accent50);
}

.eo4ams-About-infoBack {
	margin-right: 0.4rem;
	cursor: pointer;
}

.eo4ams-About-infoBack svg {
	width: 1.8rem;
	height: 1.8rem;
	margin-bottom: -0.55rem;
}


@use 'sass:math';
@import '../../../../../../styles/variables';

.eo4ams-CaseStyle {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: .5rem;
}

.eo4ams-CaseStyle:first-child {
  margin-top: 0;
}

.eo4ams-CaseStyle-label {
  font-size: $a1;
  font-weight: 700;
  color: $accentedTextColor;
}

.eo4ams-CaseStyle-switch {
  margin: .1rem -.5rem .5rem 0;
  display: flex;
}

.eo4ams-CaseStyle-switch .ptr-button-caption {
  font-size: .875rem;
  padding: calc(0.6rem - 1px) 0 calc(0.45rem - 1px)
}

.eo4ams-CaseStyle-legend {
  font-size: $b1;
  margin-bottom: m(1);
}
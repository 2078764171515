@use 'sass:math';
@import '../../../styles/variables';

// Categories
.eo4ams-LegendCategories {
    max-width: $sidePanelWidth;
}

.eo4ams-LegendCategories.is-in-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: .15rem;
}

.eo4ams-LegendCategories:not(:last-child) {
    margin-bottom: .25rem;
}

.eo4ams-LegendCategory {
    display: flex;
    align-items: flex-start;
    grid-gap: .35rem;
    line-height: 1.15rem;
}

.eo4ams-LegendCategories.is-in-row .eo4ams-LegendCategory {
    margin-right: .25rem;
}

.eo4ams-LegendCategory:not(:last-child) {
    margin-bottom: 0
}

.eo4ams-LegendCategory-symbol {
    min-width: 1.5rem;
    height: .85rem;
    margin-top: .15rem;
    border: 1px solid;
}

.eo4ams-LegendCategory-symbol.line {
    width: 1.5rem;
    border: 0;
}

.eo4ams-LegendCategory-label {
    color: var(--base80);
}
@use 'sass:math';
@import '../../../../../styles/variables';

.eo4ams-CaseOverview {
    color: var(--accent95);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.eo4ams-CaseOverview-header {
    padding: $sidePanelPadding $sidePanelPadding 0 $sidePanelPadding;
    background: var(--accent15);
}
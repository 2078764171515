@import '~@gisatcz/ptr-core/lib/styles/_variables';

// Breakpoints
$width-breakpoints: (
        x-small: 575.98px,
        small: 767.98px,
        medium: 991.98px,
        large: 1199.98px,
        x-large: 1399.98px,
        xx-large: 1599.98px,
        xxx-large: 2599.98px
);

$height-breakpoints: (
        x-small: 575.98px,
        small: 767.98px,
        medium: 991.98px,
        large: 1199.98px,
        x-large: 1399.98px,
        xx-large: 1599.98px,
        xxx-large: 2599.98px
);

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin maxWidth($breakpoint) {
  // If the key exists in the map
  @if map-has-key($width-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($width-breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($width-breakpoints)}.";
  }
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin minWidth($breakpoint) {
  // If the key exists in the map
  @if map-has-key($width-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($width-breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($width-breakpoints)}.";
  }
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin maxHeight($breakpoint) {
  // If the key exists in the map
  @if map-has-key($height-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-height: map-get($height-breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($height-breakpoints)}.";
  }
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin minHeight($breakpoint) {
  // If the key exists in the map
  @if map-has-key($height-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-height: map-get($height-breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($height-breakpoints)}.";
  }
}

@function hexToRGB($color) {
  // @return rgb(red($color), green($color), blue($color))
  @return red($color), green($color), blue($color);
}

$breakpoint-large: 1200px;
$breakpoint-medium: 1024px;
$breakpoint-small: 768px;
$breakpoint-extraSmall: 480px;

$lightAccent0: hsl(40,100%,99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(196.36,47.83%,95.49%);
$lightAccent10: hsl(198.46,59.09%,91.37%);
$lightAccent15: hsl(198.46,60%,87.25%);
$lightAccent20: hsl(199.25,62.35%,83.33%);
$lightAccent25: hsl(199.7,63.81%,79.41%);
$lightAccent30: hsl(200.49,65.08%,75.29%);
$lightAccent35: hsl(200.63,65.75%,71.37%);
$lightAccent40: hsl(200.37,65.27%,67.25%);
$lightAccent45: hsl(200.32,65.96%,63.14%);
$lightAccent50: hsl(200.43,66.35%,59.22%);
$lightAccent50rgb: 82, 173, 220;
$lightAccent55: hsl(200.32,52.1%,53.33%);
$lightAccent60: hsl(200.18,45.45%,47.45%);
$lightAccent65: hsl(200.21,44.6%,41.76%);
$lightAccent70: hsl(200,44.26%,35.88%);
$lightAccent75: hsl(199.7,43.79%,30%);
$lightAccent80: hsl(199.25,43.09%,24.12%);
$lightAccent85: hsl(198.46,41.94%,18.24%);
$lightAccent90: hsl(197.5,37.5%,12.55%);
$lightAccent95: hsl(192,29.41%,6.67%);
$lightAccent100: hsl(45,100%,0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45,100%,0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(192,29.41%,6.67%);
$darkAccent10: hsl(197.5,37.5%,12.55%);
$darkAccent15: hsl(198.46,41.94%,18.24%);
$darkAccent20: hsl(199.25,43.09%,24.12%);
$darkAccent25: hsl(199.7,43.79%,30%);
$darkAccent30: hsl(200,44.26%,35.88%);
$darkAccent35: hsl(200.21,44.6%,41.76%);
$darkAccent40: hsl(200.18,45.45%,47.45%);
$darkAccent45: hsl(200.32,52.1%,53.33%);
$darkAccent50: hsl(200.43,66.35%,59.22%);
$darkAccent50rgb: 82, 173, 220;
$darkAccent55: hsl(200.32,65.96%,63.14%);
$darkAccent60: hsl(200.37,65.27%,67.25%);
$darkAccent65: hsl(200.63,65.75%,71.37%);
$darkAccent70: hsl(200.49,65.08%,75.29%);
$darkAccent75: hsl(199.7,63.81%,79.41%);
$darkAccent80: hsl(199.25,62.35%,83.33%);
$darkAccent85: hsl(198.46,60%,87.25%);
$darkAccent90: hsl(198.46,59.09%,91.37%);
$darkAccent95: hsl(196.36,47.83%,95.49%);
$darkAccent100: hsl(40,100%,99.41%);
$darkAccent100rgb: 255, 254, 252;


// App variables
$sidePanelWidth: 37rem;
$sidePanelPadding: m(1);
$headerHeight: 3rem;

$floatingElementShadow: 0 2px 5px rgba(0, 0, 0, 0.35);


$baseTextColor: var(--base90);
$secondaryTextColor: var(--base55);
$accentedTextColor: var(--accent55);

$baseIconColor: var(--base80);
$accentedIconColor: var(--accent55);
$secondaryIconColor: var(--base60);

// Map
$iconSize: 1.25rem;
$iconToolSize: 2rem;
$hoverBackgroundColor: rgba(var(--base50rgb), 0.12);

@mixin frozenGlassEffect {
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
}

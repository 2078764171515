@use 'sass:math';
@import '../../../../../../styles/variables';

.eo4ams-AttributesList {

}

.eo4ams-AttributesList > div {
    //column-count: 2;
    //column-gap: m(math.div(4,3));
    position: relative;
    height: 100%;
}

//.eo4ams-AttributesList:after {
//    content: " ";
//    display: block;
//    position: absolute;
//    right: 20px;
//    bottom: 0;
//    left: 0;
//    height: 3rem;
//    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
//}

.eo4ams-AttributeSet {
    margin: m(math.div(1,3)) 0 m(math.div(2,3));
}

.eo4ams-AttributeSet:first-child {
    margin: 0 0 m(math.div(2,3));
}


.eo4ams-AttributeSet > h5 {
    padding: .15rem m(1);
    margin: 0;
    color: $accentedTextColor;
}

.eo4ams-AttributeRecord {
    display: flex;
    line-height: 1.15;
    padding: .25rem m(1);
    color: $baseTextColor;
    align-items: baseline;
    font-size: $b1;
}

.eo4ams-AttributeRecord.is-active {
    background: var(--accent25);
}

.eo4ams-AttributeRecord:hover {
    background: var(--accent15);
}

.eo4ams-AttributeRecord-name {
    flex: 1;
}

.eo4ams-AttributeRecord-value {
    font-weight: bold;
    text-align: right;
    display: flex;
    align-items: center;
    max-width: 20rem;
}

.eo4ams-AttributeRecord-value.is-emphasized {
    font-size: $a1;
    color: $accentedTextColor;
}

.eo4ams-AttributeRecord-value-color {
    display: block;
    min-width: .75rem;
    height: .75rem;
    margin-right: .5rem;
    border-radius: .375rem;
}

.eo4ams-AttributeRecord-unit {
    font-size: $b1;
    margin-left: .25rem;
    width: 1rem;
    color: var(--base70);
}
@use 'sass:math';
@import '../../../../../../styles/variables';

.eo4ams-Charts {
  padding: m(1);
  overflow-x: hidden;
}

.eo4ams-Charts-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.eo4ams-Charts-headerInfo {
	cursor: pointer;
}

.eo4ams-Charts-shortDescription {
	font-size: 0.85rem;
	margin: 1rem 0;
}

.eo4ams-Charts p {
  font-size: $b1;
  color: var(--base60);
  text-align: center;
}

.eo4ams-Charts p > em {
  color: var(--base70);
  font-weight: bold;
}

.eo4ams-Charts h4 {
  margin: 0;
  color: $accentedTextColor;
  text-align: center;
}

.eo4ams-Charts h5 {
  margin: 0;
  font-size: $b1;
  color: $accentedTextColor;
  text-align: center;
}

.eo4ams-Charts-section {
  display: flex;
  justify-content: center;
  width: 100%;
  //grid-gap: 2rem;
  margin: m(2/3) 0 m(2);
}

.eo4ams-Charts-section > div {
  flex: 1;
  max-width: 50%;
}
.eo4ams-Charts-section.full-width > div {
  max-width: 100%;
}


.eo4ams-Charts-section.chart-table {
  margin-bottom: m(2);
}

.eo4ams-Charts-section.chart-table .eo4ams-ChartWrapper {
  max-width: 14rem;
}

.eo4ams-Charts-section.chart-table .eo4ams-TableWrapper {
  max-width: none;
}

.eo4ams-ChartWrapper {
  height: 12rem;
}

.eo4ams-Charts-section.bar-charts .eo4ams-ChartWrapper {
  width: 12rem;
  position: relative;
  margin: 0 auto;
}
@use 'sass:math';
@import '../../../../../../styles/variables';

// CardsGroup
.eo4ams-CaseCardsGroup {
  width: 100%;
  padding: m(1);

  @include maxWidth('small') {
    font-size: $b2;
  }
}

.eo4ams-CaseCardsGroup.disabled {
  pointer-events: none;
}

.eo4ams-CaseCardsGroup-title {
  width: 100%;
  max-width: 100%;
  margin: 0 0 1rem;
  color: var(--base90);
	display: flex;
	justify-content: center;
	align-items: center;
}

.eo4ams-CaseCardsGroup-titleText {
	margin: 0;
}

.eo4ams-CaseCards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  grid-gap: m(1);
  max-width: 85rem;
  flex-wrap: wrap;

  @include maxWidth('small') {
    grid-gap: m(math.div(2, 3));
  }
}

.eo4ams-CaseCardsGroup-titleInfo {
	display: flex;
	align-items: center;
	margin-left: 0.4rem;
	cursor: pointer;
}

.eo4ams-CaseCardsGroup-titleInfo svg {
	width: 1.6rem;
	height: 1.6rem;
}